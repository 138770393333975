#deal-parties-plugin
  .uncollapsed-fields-container
    .Collapsible__contentInner
      padding: 8px


#documents-unavailable
  border: 1px solid #dbdbdb
  border-radius: 5px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1)
  padding: 20px
  margin: 20px 30px
  width: 60%
  min-width: 500px
